import React from "react"

const Primo = () => (
    <svg viewBox="0 0 165 97" fill="none" xmlns="http://www.w3.org/2000/svg" className="img-resp">
        <title>Primo logo</title>
<path d="M163.762 46.3292C163.762 46.2727 163.757 46.2161 163.755 46.1572C163.739 45.8369 163.72 45.5236 163.694 45.208C163.694 45.1844 163.694 45.1609 163.689 45.1373C163.689 45.142 163.689 45.1444 163.689 45.1468C163.479 42.6217 162.921 40.1626 162.047 37.7789C162.05 37.7554 162.047 37.7342 162.05 37.7106C161.739 36.8933 161.388 36.0854 161.009 35.2845C160.99 35.2515 160.973 35.2139 160.959 35.1809C160.86 34.976 160.764 34.7734 160.662 34.5732C160.53 34.3094 160.399 34.0479 160.257 33.7888C160.201 33.6875 160.146 33.5839 160.09 33.4826C160.019 33.3507 159.949 33.2212 159.873 33.0892C159.876 33.0963 159.878 33.1034 159.88 33.1057C149.057 13.8759 118.276 0 81.9835 0C44.3718 0 12.6723 14.9005 2.97963 35.2233C2.94901 35.3128 2.9231 35.4046 2.89248 35.4989C1.46979 38.5845 0.588857 41.8138 0.308559 45.1491C0.308559 45.1444 0.308559 45.142 0.306204 45.1397C0.303848 45.1632 0.301493 45.1868 0.301493 45.2104C0.275583 45.5283 0.25674 45.8393 0.242607 46.1596C0.237896 46.2161 0.235541 46.2727 0.233185 46.3315C0.219053 46.699 0.207275 47.0617 0.207275 47.4292C0.207275 57.925 6.15948 67.7896 16.2172 75.8311C16.7307 76.3116 17.2725 76.7662 17.8613 77.1761C18.2452 77.4469 18.6363 77.706 19.032 77.9604C34.0338 88.6753 56.6696 95.6474 81.9929 95.6474C127.163 95.6474 163.783 73.4686 163.783 47.4292C163.788 47.0594 163.779 46.6966 163.762 46.3292Z" fill="#f1f2f3"/>
<path d="M160.757 44.5791C160.757 68.4068 125.498 87.7261 82.0001 87.7261C38.5045 87.7261 3.24585 68.4068 3.24585 44.5791C3.24349 20.7491 38.5045 1.43213 82.0001 1.43213C125.498 1.43213 160.757 20.7491 160.757 44.5791Z" fill="#1A1715"/>
<path d="M163.974 46.7274C163.974 46.6661 163.969 46.6096 163.967 46.5531C163.953 46.2327 163.932 45.9124 163.906 45.5968C163.906 45.5732 163.903 45.5496 163.901 45.5261C163.901 45.5308 163.901 45.5332 163.901 45.5355C163.55 41.291 162.233 37.2184 160.076 33.3838C161.444 36.8769 162.17 40.5019 162.17 44.207C162.17 70.2653 126.358 91.1274 82 91.4148C37.6399 91.1298 1.83253 70.2653 1.83253 44.2094C1.83253 40.5043 2.55565 36.8793 3.92417 33.3861C1.76894 37.2185 0.452245 41.291 0.0989285 45.5355C0.0989285 45.5308 0.0989285 45.5284 0.0965731 45.5261C0.0965731 45.5496 0.0942176 45.5732 0.0918622 45.5968C0.0659523 45.9124 0.0447534 46.2327 0.0329762 46.5531C0.0282653 46.6096 0.0259099 46.6661 0.0235544 46.7274C0.00942176 47.0948 0 47.4599 0 47.8321C0 74.067 36.7119 96.4154 82 96.4154C127.29 96.4154 164.002 74.0646 164.002 47.8321C164 47.4599 163.991 47.0925 163.974 46.7274Z" fill="#1A1715"/>
<path d="M74.8678 20.012C72.5595 20.012 70.7646 22.0212 70.7646 24.4779C70.7646 26.6802 72.4841 28.6894 74.6064 28.6894C76.8252 28.6894 78.7473 26.7179 78.7473 24.3106C78.7496 21.9364 77.0843 20.012 74.8678 20.012Z" fill="#f1f2f3"/>
<path d="M58.6411 45.0926V36.8298C58.6411 36.361 59.2347 36.0854 59.8376 36.0854C60.8293 36.0854 61.3969 37.5317 63.1989 37.5317C64.8736 37.5317 66.3622 36.1726 66.3622 34.0668C66.3622 31.6808 64.7511 30.2651 62.8008 30.2651C60.7987 30.2651 59.4419 31.1602 57.6 32.4769V30.2651H56.003L48.8872 31.4876V35.3293L49.3889 35.221C52.2626 35.5884 51.9257 36.6649 51.9257 39.7529L51.9658 45.1327C51.2426 59.4279 49.5726 61.2416 49.5726 61.2416L61.013 59.1641C61.013 59.1617 58.6411 56.7168 58.6411 45.0926Z" fill="#f1f2f3"/>
<path d="M80.1627 57.4069C78.3608 53.8549 78.481 45.705 78.481 45.705V30.6255H75.8546L68.7341 31.8338V35.6237H69.005C71.8881 35.9983 71.808 36.9239 71.808 39.9648V45.7027C71.808 45.7027 72.1071 53.0375 69.9967 57.8968L80.4266 57.388" fill="#f1f2f3"/>
<path d="M121.43 62.311L111.083 60.0568C112.112 58.1913 112.039 52.8586 112.039 52.8586V46.4895V38.2171C112.039 36.8157 111.735 35.0255 110.016 35.0255C108.032 35.0255 106.141 35.7863 105.684 36.6414V46.6614C105.684 46.6614 105.552 55.6215 107.031 59.4161L96.9924 58.3562C98.8462 55.4896 98.6601 46.579 98.6601 46.579V38.0405C98.6601 36.6861 98.6601 35.0279 96.8582 35.0279C95.5627 35.0279 93.6147 35.7416 92.3098 36.6437V46.7109C92.3098 46.7109 92.1779 54.5568 93.6571 58.1088L83.5546 57.593C85.2741 53.9067 85.2906 45.2764 85.2906 45.2764V39.6964C85.2906 36.7379 85.3848 35.8405 82.3581 35.4754H82.0684V31.7915L89.5375 30.6138H91.5608V32.3309C93.8385 30.847 96.2151 30.032 98.5824 30.032C100.773 30.032 102.697 30.847 104.219 32.5971C106.186 31.026 108.826 30.032 112.404 30.032C114.425 30.032 116.236 30.4866 117.574 32.1519C119.046 33.8596 119.046 35.4754 119.046 37.6754V45.2764C119.042 45.2717 118.988 57.9369 121.43 62.311Z" fill="#f1f2f3"/>
<path d="M151.288 49.184C151.288 35.0136 143.086 29.9753 136.752 29.9753C129.123 29.9753 122.111 36.5352 122.111 49.0968C122.111 60.6997 128.277 68.3078 136.752 68.3078C142.474 68.3078 151.288 63.8254 151.288 49.184ZM143.293 48.7765C143.293 58.0616 141.067 63.2083 136.849 63.2083C133.443 63.2083 130.301 59.9907 130.301 49.0544C130.301 39.4913 132.953 34.9901 136.849 34.9901C141.364 34.9901 143.293 40.5018 143.293 48.7765Z" fill="#f1f2f3"/>
<path d="M19.4394 69.6668C19.4394 69.6668 19.4394 69.7234 22.2189 68.3855C25.5942 66.7626 28.7364 65.4742 32.6582 64.3506C32.6582 64.3506 29.6385 60.1061 29.5443 51.2756V45.5919H33.2541C39.3147 45.5919 46.3103 43.7546 46.3103 33.565C46.3103 29.304 44.7416 25.222 41.8986 23.2647C39.4183 21.5522 36.3256 21.5522 33.6168 21.5522H17.9673L18.3065 26.4893C21.7407 26.9745 21.8161 28.1357 21.8161 32.326V51.2756C21.8161 66.3457 19.4394 69.6668 19.4394 69.6668ZM29.5443 31.4286C29.5773 30.5383 29.4948 26.9698 30.0177 26.3149C30.6301 25.6578 31.8503 25.5377 32.5969 25.5377C37.0417 25.5377 37.6941 29.1061 37.6941 34.208C37.6941 36.1678 36.9969 38.3018 35.7768 39.4795C34.4766 40.7255 32.2436 40.9022 30.6773 40.9022H29.5419V31.4286H29.5443Z" fill="#f1f2f3"/>
</svg>
)

export default Primo