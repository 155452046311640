import React from 'react'
import Hj from "../components/svg/hj"
import Sani from "../components/svg/sani"
import Sanofi from "../components/svg/sanofi"
import Alcon from "../components/svg/alcon"
import Pork from "../components/svg/pork"
import Maccas from "../components/svg/maccas"
import Primo from "./svg/primo"
import {StaticImage} from "gatsby-plugin-image"

export default function ClientLogos() {
  
  return (
    <div className="cl-row">
        <div className="cl"
          data-sal="fade" 
          data-sal-delay="100"
          data-sal-duration="400"
        >
          <div className="cl-img with-pad"><Pork /></div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="200"
          data-sal-duration="400"
        >
          <div className="cl-img"><Sani /></div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="300"
          data-sal-duration="400"
        > 
          <div className="cl-img with-pad"><Hj /></div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="400"
          data-sal-duration="400"
        >
          <div className="cl-img"><Alcon /></div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="500"
          data-sal-duration="400"
        >
          <div className="cl-img with-pad">
            <StaticImage 
                src="../images/nestle-logo.png"
                alt="Nestle logo"
                className="img-resp"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                backgroundcolor="white"
                layout="fullWidth"
                quality="100%"
            />
          </div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="600"
          data-sal-duration="400"
        >
          <div className="cl-img">
            <StaticImage 
                src="../images/arnotts-logo.png"
                alt="Arnott's logo"
                className="img-resp"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                backgroundcolor="white"
                layout="fullWidth"
                quality="100%"
            />
          </div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="700"
          data-sal-duration="400"
        >
          <div className="cl-img">
            <StaticImage 
                src="../images/bega-logo.png"
                alt="Bega logo"
                className="img-resp"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                backgroundcolor="white"
                layout="fullWidth"
                quality="100%"
            />
          </div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="800"
          data-sal-duration="400"
        >
          <div className="cl-img with-pad">
            <Primo />
          </div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="900"
          data-sal-duration="400"
        >
          <div className="cl-img with-pad"><Maccas /></div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="1000"
          data-sal-duration="400"
        >
          <div className="cl-img">
          <StaticImage 
                src="../images/allianz-logo.svg"
                alt="Allianz logo"
                className="img-resp"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                backgroundcolor="white"
                layout="fullWidth"
                quality="100%"
            />
          </div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="900"
          data-sal-duration="500"
        >
          <div className="cl-img with-pad"><Sanofi /></div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="1000"
          data-sal-duration="400"
        >
          <div className="cl-img with-pad">
          <StaticImage 
                src="../images/sgh.png"
                alt="SGH logo"
                className="img-resp"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                backgroundcolor="white"
                layout="fullWidth"
                quality="100%"
            />
          </div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="1000"
          data-sal-duration="400"
        >
          <div className="cl-img">
          <StaticImage 
                src="../images/sunrice.svg"
                alt="SunRice logo"
                className="img-resp"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                backgroundcolor="white"
                layout="fullWidth"
                quality="100%"
            />
          </div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="1000"
          data-sal-duration="400"
        >
          <div className="cl-img">
          <StaticImage 
                src="../images/tpg.svg"
                alt="TPG logo"
                className="img-resp"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                backgroundcolor="white"
                layout="fullWidth"
                quality="100%"
            />
          </div>
        </div>

        <div className="cl"
          data-sal="fade" 
          data-sal-delay="1000"
          data-sal-duration="400"
        >
          <div className="cl-img with-pad">
          <StaticImage 
                src="../images/VF.svg"
                alt="Vodafone logo logo"
                className="img-resp"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                backgroundcolor="white"
                layout="fullWidth"
                quality="100%"
            />
          </div>
        </div>
    </div>
  )
}